<template>
  <div class="grid grid-cols-8 p-5 gap-5">
    <router-link
      v-for="brand in brands"
      :key="brand._id"
      :to="`/partmanuals/${brand._id}`"
      class="
        py-6
        cursor-pointer
        z-10
        relative
        bg-gray-200
        text-center
        rounded-2xl
        transform
        hover:shadow-xl hover:-translate-y-1 hover:scale-108
        duration-300
        ease-in-out
      "
    >
      <p>
        <svg
          class="mx-auto mb-4"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M11 21H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2v2M19 15v6M16 18h6"
          />
        </svg>
      </p>
      <h1 class="uppercase text-black font-bold text-xs py-1">
        {{ brand.name }}
      </h1>
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "BrandPartManualsFolders",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getBrands"]),
  },
  computed: {
    ...mapGetters(["brands"]),
  },
  mounted() {
    this.getBrands();
  },
};
</script>

<style>
</style>